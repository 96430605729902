/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: CRM.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/Modules/CRM.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 19/11/24;
 * Updated at: 19/11/24;
 */

import { OggettoConfig } from "src/app/shared/_services/dynamic-crud.service";

function Mansionario() {
    var OBJ: OggettoConfig = {
        HasAddList: {},//da usare come hideif
        Capacita: ['gestione-mansioni'],
        Id: "mansionario",
        listTitle: "Mansionario",
        RestRoute: '/anagrafica/clienti/mansione',
        ListResponseHasData: true,
        GetResponseHasData: false,
        ListDefaultFilter: {},
        OnSaveMap: (x) => { { return x } },
        MenuItems: [
            {
                path: "/shared/dynamiclist/mansionario",
                title: "Mansionario",
                icon: "fas fa-graduation-cap",
                class: "",
                capacita: ['gestione-mansioni'],
            },
        ],
        campi: [
            {
                nome: 'Denominazione',
                oggetti: ['denominazione'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-12'
            },
            {
                nome: 'Compiti',
                oggetti: ['compiti'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-6'
            },
            {
                nome: 'Requisiti',
                oggetti: ['requisiti'],
                modificabile: true,
                inputType: 'text',
                class: 'col-lg-6'
            },
            {
                nome: 'Abilità / Competenze',
                source: '/anagrafica/clienti/mansione/abilita',
                responseHasData: false,

                oggetti: ['abilita'],
                internalOutput: 'abilitaObject',
                OutputField: 'abilita',
                OnChange: 'OnAbilitaChange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: false,
                    closeDropDownOnSelection: false,
                    singleOutput: false,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-12',
                visibleifProhibited: true
            },
        ],
        Azioni: [
            {
                nome: 'Elimina',
                close: true,
                class: ' btn ',
                labelClass: 'ml-1 text-danger font-weight-bold',
                type: 'POST',
                restRoute: '/anagrafica/clienti/mansione/delete',
                preAlert: 'Sei sicuro di voler eliminare questo elemento?',
                capacita: ['gestione-mansione'],
                icon: 'fas fa-trash text-danger',
                hideif: { fieldnotexist: 'id' },
                placeholder: 'Elimina',
                successMex: 'Elemento eliminato con successo',
                parameter: [{ nome: "id", parameter: 'id' }]
            }
        ],
        Headers: [
            { nome: 'Denominazione', oggetti: ['denominazione'], inputType: 'text' },
            { nome: 'Compiti', oggetti: ['compiti'], inputType: 'text' },
            { nome: 'Requisiti', oggetti: ['requisiti'], inputType: 'text' },
            { nome: 'Abilità', oggetti: ['abilitaObject'], type: 'objArray', field: ['label'] },
        ],
        Filters: [],
    }
    return OBJ;
}
function Mansione() {
    var OBJ: OggettoConfig = {
        HasAddList: {},//da usare come hideif
        Capacita: ['gestione-mansioni'],
        Id: "mansioni",
        listTitle: "Mansioni",
        RestRoute: '/anagrafica/clienti/mansioni',
        ListResponseHasData: true,
        GetResponseHasData: false,
        ListDefaultFilter: {},
        OnSaveMap: (x) => { { return x } },
        MenuItems: [

        ],
        campi: [
            {
                nome: 'Da',
                oggetti: ['data'],
                modificabile: true,
                inputType: 'date',
                class: 'col-lg-6'
            },
            {
                nome: 'A',
                oggetti: ['data_fine'],
                modificabile: true,
                inputType: 'date',
                class: 'col-lg-6'
            },
            {
                nome: 'Mansione',
                source: '/anagrafica/clienti/mansione/list',
                responseHasData: true,
                parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 200 }],
                oggetti: ['mansione'],
                internalOutput: 'mansioneObject',
                OutputField: 'mansione',
                OnChange: 'OnmansioneChange',
                modificabile: true,
                inputType: 'singleDropdown',
                placeholder: 'Seleziona',
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'denominazione',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: false,
                },
                class: 'col-lg-12',
                visibleifProhibited: true
            },
        ],
        Azioni: [
            {
                nome: 'Elimina',
                close: true,
                class: ' btn ',
                labelClass: 'ml-1 text-danger font-weight-bold',
                type: 'POST',
                restRoute: '/anagrafica/clienti/mansioni/delete',
                preAlert: 'Sei sicuro di voler eliminare questo elemento?',
                capacita: ['gestione-mansione'],
                icon: 'fas fa-trash text-danger',
                hideif: { fieldnotexist: 'id' },
                placeholder: 'Elimina',
                successMex: 'Elemento eliminato con successo',
                parameter: [{ nome: "id", parameter: 'id' }]
            }
        ],
        Headers: [
            { nome: 'Da', oggetti: ['data'], inputType: 'date' },
            { nome: 'A', oggetti: ['data_fine'], inputType: 'date' },
            { nome: 'Mansione', oggetti: ['mansioneObject'], type: 'object', field: ['label'] },
        ],
        Filters: [],
    }
    return OBJ;
}

export var MansioniEnv = () => {
   
    var env = {
         Cruds: [Mansionario(), Mansione()],
        Menu: [...Mansione().MenuItems, ...Mansionario().MenuItems],
        Modules: {
            "mansioni":true,
        }
    };
    return env;
}


