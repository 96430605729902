
// production: true,
// apiUrl: 'https://fbs.wcs-servizi.it/api',
  
import { Environment as FbsEnv } from './BaseEnvironments/Fbs'

export const Environment = () => {
  var env: any = FbsEnv();
  env.apiUrl = 'https://fbs.wcs-servizi.it/api';
  env.logoPath = 'assets/images/wcs.jpeg';
  env.production = true;
  return env;
}
export const environment = Environment();